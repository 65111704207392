<template>
  <div class="pay">
    <div class="main">
      <div class="box">
        <div class="row">
          <span>申请说明</span>
          <em style="color: #007bff" @click="explain">查阅开课申请说明</em>
        </div>
        <div class="row">
          <span>课程名称</span>
          <em>{{ info.bookname }}</em>
        </div>
        <div class="row">
          <span>名称</span>
          <em>{{ info.alias }}</em>
        </div>
        <div class="row">
          <span>开课地点</span>
          <em>
            <span v-show="info.pcode">{{ info.pcode }}-</span>
            <span v-show="info.ccode">{{ info.ccode }}-</span>
            <span v-show="info.dcode">{{ info.dcode }}</span>
            <span v-show="!info.pcode">未填写</span>
          </em>
        </div>
        <div class="row">
          <span>所属子公司</span>
          <em>{{ info.company_name }}</em>
        </div>
        <div class="row">
          <span>开课人数</span>
          <em>{{ info.num }}人</em>
        </div>
        <div class="row">
          <span>主讲老师</span>
          <em>{{ info.teacher }}</em>
        </div>
        <div class="row">
          <span>保证金额</span>
          <em style="color: #007bff">{{ info.pay_price }}元</em>
        </div>
        <div class="row">
          <span>开课时间</span>
          <em class="font-26">{{ info.starttime }} ~ {{ info.endtime }}</em>
        </div>
        <div class="row" style="height: 100px" v-if="is_proof == 1">
          <span>上传合同</span>
          <van-uploader
            v-model="fileList"
            :after-read="onRead"
            :max-count="1"
            accept="image/*"
            :preview-size="size"
            style="margin: 10px -8px 10px 0"
          >
          </van-uploader>
        </div>
      </div>

      <div class="title">支付方式</div>
      <div class="box pay">
        <div class="row way" @click="payWay('weixin')">
          <span>
            微信支付
            <br />
            <sub>微信支付，安全快捷</sub>
            <i class="jym-weixin"></i>
          </span>
        </div>
        <div class="row way" @click="payWay('alipay')">
          <span>
            支付宝支付
            <br />
            <sub>简单、安全、快速</sub>
            <i class="jym-zhifubao"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { MessageBox } from "mint-ui";
import { Uploader } from "vant";
import { siteDeposit, sitePayDeposit, siteUploadProof } from "@api/order";
import { isWeixin } from "@utils";
import { pay } from "@libs/wechat";
const _isWeixin = isWeixin();
Vue.use(Uploader);
export default {
  name: "apply",
  data() {
    return {
      id: "",
      file: null,
      files: {
        name: "",
        type: "",
      },
      is_proof: '',
      fileList: [],
      info: {},
      positionPrice: '',
      messageSite: ''
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getData();    
  },
  methods: {
    // 开课申请说明
    explain() {
      MessageBox({
        title: "",
        message: this.messageSite
      }).then((status) => {});
    },

    // 获取列表数据
    getData() {
      let that = this;

      siteDeposit(that.id)
        .then((res) => {
          that.$set(that, "info", res.data);
          that.is_proof = res.data.is_proof;
          that.positionPrice = res.data.pay_price;
          that.messageSite= `<i class="jym-zhuyi"></i><h2>开课申请说明：</h2><p>为了避免主办方申请站点成功后，随意取消站点导致已经报名的学员无法正常上课以及学员差旅费损失，也为了聚亿美工作人员提前做好会前准备工作，给学员提供更好的服务和体验！</p>
          <p>
            自2021年5月1日起，所有主办方申请开课需要缴纳
            <span class="gold-num">${that.positionPrice}</span> 元保证金，申请站点正常开课成功后退回
            <span class="gold-num">${that.positionPrice}</span> 元！
          </p>
          <p>
            如需取消站点，应在当期课程现场签到日15天前申请，如在当期课程现场签到日10-15天申请，扣除保证金
            <span class="gold-num">3000</span> 元，如在当期课程现场签到日10天内申请，保证金不予退还，且不可下次使用！
          </p>
          <p class="bottom">
            聚亿美教育咨询有限公司
            <br />2021年5月1日
          </p>`
          res.data.proof ? that.fileList = [{url: res.data.proof}] : '';
          that.explain();
        })
        .catch((err) => {
          that.$dialog.error(err.msg);
          if(err.res.data.status === 400) {
            setTimeout(() => {
              that.$router.push('/user')
            }, 2000)
          }
        });
    },

    // 支付
    payWay(way) {
      let that = this,
        url = "/lesson/status",
        params = {
          id: that.id,
          payType: way,
          from: _isWeixin ? "weixin" : "weixinh5",
        };

      if(that.is_proof == 1 && !that.fileList.length) {
        that.$dialog.error('请上传合同！')
        return false
      }
      sitePayDeposit(params)
        .then((res) => {
          const data = res.data;
          switch (res.data.status) {
            case "PAY_ERROR":
              that.$dialog.error(res.msg || "创建订单失败");
              break;
            case "SUCCESS":
              that.$dialog.success(res.msg);
              that.$router.replace({
                path: url,
                query: { id: that.id, way: "yue" },
              });
              break;
            case "WECHAT_H5_PAY":
              that.$router.replace({
                path: url,
                query: { id: that.id, way: "weixin-h5" },
              });
              setTimeout(() => {
                location.href = data.result.jsConfig.mweb_url;
              }, 100);
              break;
            case "ALIPAY":
              if (isWeixin()) {
                // 微信端
                that.$router.push({
                  path: "/alipay",
                  query: {
                    url: data.result.body,
                    query: { id: that.id, way: "alipay" },
                  },
                });
              } else {
                setTimeout(() => {
                  location.href = data.result.body;
                }, 100);
                that.$router.replace({
                  path: url,
                  query: { id: that.id, way: "alipay" },
                });
              }
              break;
            case "WECHAT_PAY":
              pay(data.result.jsConfig).finally(() => {
                this.$router.replace({
                  path: url,
                  query: { id: that.id, way: "weixin" },
                });
              });
              break;
          }
        })
        .catch((err) => {
          that.$dialog.error(err.msg || "创建订单失败");
        });
    },

    // 组件方法 获取 流
    async onRead(file) {
      this.$dialog.loading.open("正在上传");
      // console.log(file);
      // console.log(file.file);
      this.files.name = file.file.name; // 获取文件名
      this.files.type = file.file.type; // 获取类型
      this.picValue = file.file; // 文件流
      this.imgPreview(this.picValue);
    },
    // 处理图片
    imgPreview(file) {
      let self = this;
      let Orientation;
      // //去获取拍照时的信息，解决拍出来的照片旋转问题
      // Exif.getData(file, function () {
      //   Orientation = Exif.getTag(this, "Orientation");
      // });
      // 看支持不支持FileReader
      if (!file || !window.FileReader) return;
      if (/^image/.test(file.type)) {
        // 创建一个reader
        let reader = new FileReader();
        // 将图片2将转成 base64 格式
        reader.readAsDataURL(file);
        // 读取成功后的回调
        reader.onloadend = function () {
          // console.log(this.result);
          let result = this.result;
          let img = new Image();
          img.src = result;
          //判断图片是否大于500K,是就直接上传，反之压缩图片
          if (this.result.length <= 500 * 1024) {
            self.headerImage = this.result;
            self.postImg();
          } else {
            img.onload = function () {
              let data = self.compress(img, Orientation);
              self.headerImage = data;
              self.postImg();
            };
          }
        };
      }
    },
    // 压缩图片
    compress(img, Orientation) {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      //瓦片canvas
      let tCanvas = document.createElement("canvas");
      let tctx = tCanvas.getContext("2d");
      // let initSize = img.src.length;
      let width = img.width;
      let height = img.height;
      //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
      let ratio;
      if ((ratio = (width * height) / 4000000) > 1) {
        // console.log("大于400万像素");
        ratio = Math.sqrt(ratio);
        width /= ratio;
        height /= ratio;
      } else {
        ratio = 1;
      }
      canvas.width = width;
      canvas.height = height;
      //    铺底色
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      //如果图片像素大于100万则使用瓦片绘制
      let count;
      if ((count = (width * height) / 1000000) > 1) {
        // console.log("超过100W像素");
        count = ~~(Math.sqrt(count) + 1); //计算要分成多少块瓦片
        //      计算每块瓦片的宽和高
        let nw = ~~(width / count);
        let nh = ~~(height / count);
        tCanvas.width = nw;
        tCanvas.height = nh;
        for (let i = 0; i < count; i++) {
          for (let j = 0; j < count; j++) {
            tctx.drawImage(
              img,
              i * nw * ratio,
              j * nh * ratio,
              nw * ratio,
              nh * ratio,
              0,
              0,
              nw,
              nh
            );
            ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
          }
        }
      } else {
        ctx.drawImage(img, 0, 0, width, height);
      }
      //修复ios上传图片的时候 被旋转的问题
      if (Orientation != "" && Orientation != 1) {
        switch (Orientation) {
          case 6: //需要顺时针（向左）90度旋转
            this.rotateImg(img, "left", canvas);
            break;
          case 8: //需要逆时针（向右）90度旋转
            this.rotateImg(img, "right", canvas);
            break;
          case 3: //需要180度旋转
            this.rotateImg(img, "right", canvas); //转两次
            this.rotateImg(img, "right", canvas);
            break;
        }
      }
      //进行最小压缩
      let ndata = canvas.toDataURL("image/jpeg", 0.25);
      tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
      this.$dialog.loading.close();
      return ndata;
    },
    // 旋转图片
    rotateImg(img, direction, canvas) {
      //最小与最大旋转方向，图片旋转4次后回到原方向
      const min_step = 0;
      const max_step = 3;
      if (img == null) return;
      //img的高度和宽度不能在img元素隐藏后获取，否则会出错
      let height = img.height;
      let width = img.width;
      let step = 2;
      if (step == null) {
        step = min_step;
      }
      if (direction == "right") {
        step++;
        //旋转到原位置，即超过最大值
        step > max_step && (step = min_step);
      } else {
        step--;
        step < min_step && (step = max_step);
      }
      //旋转角度以弧度值为参数
      let degree = (step * 90 * Math.PI) / 180;
      let ctx = canvas.getContext("2d");
      switch (step) {
        case 0:
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0);
          break;
        case 1:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(degree);
          ctx.drawImage(img, 0, -height);
          break;
        case 2:
          canvas.width = width;
          canvas.height = height;
          ctx.rotate(degree);
          ctx.drawImage(img, -width, -height);
          break;
        case 3:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(degree);
          ctx.drawImage(img, -width, 0);
          break;
      }
    },
    //将base64转换为文件
    dataURLtoFile(dataurl) {
      var arr = dataurl.split(","),
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], this.files.name, {
        type: this.files.type,
      });
    },

    // 生成图片文件流
    async postImg() {
      this.file = this.dataURLtoFile(this.headerImage);
      this.fileUpload()
    },

    // 上传
    fileUpload() {
      let that = this

      let formData = new FormData()
      let fileName = that.id+'_'+new Date().getTime()+'.jpg'
      formData.append('file', that.file, fileName)
      siteUploadProof(that.id, formData).then(res => {
        that.$dialog.success(res.msg)
        that.$dialog.loading.close();
      }).catch(err => {
        that.$dialog.error(err.msg)
        that.$dialog.loading.close();
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.pay {
  .main {
    .title {
      padding-left: 0.3rem;
      margin-top: 0.2rem;
      line-height: 0.4rem;
      color: #007bff;
    }
    .pay {
      display: flex;
      justify-content: space-between;
      .way {
        width: 50%;
      }
      .way:first-child {
        border-bottom: none;
        border-right: 0.02rem solid #eee;
      }
    }
    .box {
      margin-top: 0.2rem;
      font-size: 0.28rem;
      background: white;
      h1 {
        font-size: 0.28rem;
        padding-left: 0.3rem;
        line-height: 0.4rem;
        padding-top: 0.2rem;
        font-weight: 500;
      }
      .row {
        padding: 0 0.3rem;
        height: 0.88rem;
        line-height: 0.88rem;
        display: flex;
        justify-content: space-between;
        border-bottom: 0.02rem solid #dedede;
        em {
          font-family: PingFang SC;
          color: #a9a9a9;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 5.5rem;
        }
        > i {
          font-size: 0.4rem;
        }
      }
      .row:last-child {
        border: none;
      }
    }
    .box:first-child {
      margin: 0;
    }
    .way {
      height: 0.98rem !important;
      span {
        font-size: 0.26rem;
        line-height: 0.32rem;
        padding: 0.17rem 0 0.17rem 0.68rem;
        position: relative;
        sub {
          font-size: 0.2rem;
          color: #a9a9a9;
        }
        i {
          position: absolute;
          font-size: 0.54rem;
          top: 0.32rem;
          left: 0;
        }
        .jym-weixin {
          color: #00c800;
        }
        .jym-zhifubao {
          color: #02a9f1;
        }
      }
    }
  }
}
</style>
